const useSidebarCollapsed = () => {
  const sidebarCollapsedData = useState<boolean>('sidebarCollapsed', () => false);

  const setSidebarCollapsed = (value: boolean) => {
    sidebarCollapsedData.value = value;
  };
  return {
    sidebarCollapsedData,
    setSidebarCollapsed,
  };
};
export default useSidebarCollapsed;
